<template>
  <div>
    <!-- 扫渠道二维码跳转到该页面，判断用户是否注册，未注册跳转注册页面注册并绑定渠道 -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Channel",
  data() {
    return {}
  },
  computed: {
    ...mapState(['userInfo', 'isLogin']),
    key() {
      return this.$route.query.key;
    },
    uri() {
      return this.$route.query.uri;
    }
  },
  created() {
    this.addSalesDoctor();
  },
  methods: {
    addSalesDoctor() {
      if (this.isLogin) {
        if (this.key) {
          location.href = decodeURIComponent(this.uri + '&uid=' +  this.userInfo.Id);
        } else {
          if (this.uri) {
            location.href = decodeURIComponent(this.uri + '&uid=' +  this.userInfo.Id);
          } else {
            this.$router.push('/myMain');
          }
        }
      } else {
        if (this.key) {
          sessionStorage.setItem("key", this.key);
        }
        if (this.uri) {
          sessionStorage.setItem("uri", this.uri);
        }
        this.$router.push('/entrance');
      }
    }
  }
}
</script>